<template>
	<div class="container mt-4">
		<h2 class="label-header">Dashboard</h2>
	</div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {

    };
  },
};
</script>

<style scoped>
.container{
	background: var(--paul-color-light);
}
</style>
